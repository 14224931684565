<template>
  <div id="homeDefault">
    <div class="default-header" style="height: 200px;z-index: 9999;">
      <div class="uf uf-ac uf-pj fwrap-w">
        <!--$router.push({name: 'index'})-->
        <div class="logo uf uf-ac cp" @click="$router.push({name: 'defaultHome'})">
          <img src="../assets/img/logo-sp.png" alt="">
          <div class="logo-word ml-3 tac">
            <div class="fwb c-white">开放式产品服务化支撑平台</div>
            <div class="f16 op-08 c-white"><span>——</span><span class="text-space">国家重点研发计划支持</span><span>——</span></div>
          </div>
        </div>
        <div class="uf-f1">
          <slot name="center-box">
          </slot>
        </div>
        <div class="uf header-right">
          <!--          <div>-->
          <!--              <el-button size="mini" type="primary" @click="$router.push({name: 'login'})">服务商进驻</el-button>-->
          <!--          </div>-->
          <div class="uf uf-ac cp" style="position:relative; margin-right: 50px">
            <!--          <el-avatar class="mr-2" :size="35" :src="loginStatus ? userAvatarUrl : defAvatarUrl"></el-avatar>-->
            <template>
<!--              <div class="f16 op-08" v-if="loginStatus" style="color: #FFFFFF">{{ name }}</div>-->
              <el-button v-if="!loginStatus" size="mini" style="background: transparent;border-color: #FFFFFF" type="warning"
                         @click="$router.push({name: 'login'})" round>登录 / 注册
              </el-button>
            </template>
          </div>
          <!--        <el-badge :value="unRead" :hidden="unRead <= 0" class="item" style="position:relative; left:-30px;">-->
          <!--          <el-button size="small" icon="el-icon-chat-dot-round" circle @click="$router.push({name: 'remind'})"></el-button>-->
          <!--        </el-badge>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {clearLoginInfo} from '../utils'

export default {
  name: 'page-header2',
  props: {},
  data() {
    return {
      defAvatarUrl: require('../../public/img/male.jpg'),
      userAvatarUrl: require('../../public/img/wuyanzu.jpg'),
      showHeaderBadge: true,
    }
  },
  computed: {
    name: {
      get() {
        return this.$store.state.user.name
      },
    },
    loginStatus: {
      get() {
        return this.$store.state.user.loginStatus
      },
      set(val) {
        this.$store.commit('user/updateLoginStatus', val)
      }
    },
    unRead: {
      get() {
        return this.$store.state.user.unRead
      },
      set(val) {
        this.$store.commit('user/updateUnRead', val)
      }
    }
  },
  watch: {},
  created() {
  },
  methods: {
    logout() {
      this.$confirm('确认退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(this.$api.SYS.LOGOUT),
          method: 'post'
        }).then(({data}) => {
          clearLoginInfo()
          this.$router.push({name: 'index'})
        })
      }).catch(() => {
      })
    },
  }
}
</script>
<style scoped>
.default-header {
  padding: 20px 60px;
  position: absolute;
  width: 100%;
  bagckground: transparent;
  min-width: 900px;
  border: 0px;
}

.logo-word {
  font-size: 1.9rem;
}

img {
  width: 3rem;
}

.header .el-menu--horizontal .el-submenu .el-submenu__title, .header .el-submenu__title {
  font-size: 16px;
  color: #333333;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
}

.el-menu.el-menu--horizontal, .el-menu--horizontal > .el-menu-item {
  border-bottom: none;
}

.el-menu--horizontal > .el-menu-item {
  font-size: 16px;
  color: #333333;
}

.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background: rgba(64, 158, 255, .1);
  color: #4647BB;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  outline: 0;
  color: #409EFF;
  background: rgba(64, 158, 255, .1);
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  /* outline: 0; */
  /* color: #303133; */
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none;
  /*background: rgba(64, 158, 255, .1);*/
  color: #409EFF;
}

.el-dialog__wrapper .logo-img {
  width: 40px;
}

.text-space {
  letter-spacing: 14px;
  margin-right: 10px;
  margin-left: 10px;
}

@media only screen and (max-width: 500px) {
  .text-space {
    letter-spacing: 8px;
    margin-right: 5px;
    margin-left: 5px;
  }
}

</style>
